<template>
  <section>
    <breadcrumb :title="$t('invoices')" :subtitle="$t('invoices_list')"/>
    <div class="columns filters is-flex is-justify-content-space-between">
      <div class="column is-flex is-align-items-center">
        <div>
          <b-field>
            <b-input :placeholder="$t('filter_search')"
                     custom-class="search-filter"
                     v-model="search"
                     type="search"
                     icon="magnify"
                     icon-clickable
                     @icon-click="searchIconClick">
            </b-input>
          </b-field>
        </div>
        <div @click="filter=!filter" class="ml-25">
          <b-icon type="is-purple" size="is-medium" :icon="filter_name"></b-icon>
        </div>
      </div>
      <div v-if="user.userType !== userTypes.DEALER && user.userType !== userTypes.DELIVERYMAN" @click="openModalRemittance">
        <img class="filter" :src="addInvoice">
      </div>
    </div>
    <template v-if="filter">
      <div class="columns">
        <div class="column is-3">
          <b-field>
            <b-input :placeholder="$t('nro_invoice')"
                     v-model="transactionNumberValue"
                     custom-class="custom-search full-input"
                     icon-clickable
            >
            </b-input>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field>
            <multiselect
                :placeholder="$t('issuer')"
                v-model="issuerFilterValue"
                :custom-label="selectName"
                open-direction="bottom"
                :show-no-results="false"
                :options="issuerFilterList">
            </multiselect>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field>
            <multiselect
                :placeholder="$t('recipient')"
                v-model="recipientFilterValue"
                :custom-label="selectName"
                open-direction="bottom"
                :show-no-results="false"
                :options="recipientFilterList">
            </multiselect>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field>
            <multiselect
                :placeholder="$t('states')"
                v-model="stateFilterValue"
                :custom-label="selectName"
                open-direction="bottom"
                :show-no-results="false"
                :options="stateFilterList">
            </multiselect>
          </b-field>

        </div>
      </div>
      <div class="columns">
        <div class="column is-3">
          <b-field>
            <b-datepicker
                :placeholder="$t('date_init')"
                v-model="dateInit"
                trap-focus>
            </b-datepicker>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field>
            <b-datepicker
                :placeholder="$t('date_end')"
                v-model="dateEnd"
                trap-focus>
            </b-datepicker>
          </b-field>
        </div>
        <div class="column is-3" v-if="user.userType === userTypes.MANAGER">
          <b-field>
            <multiselect
                :placeholder="$t('agency')"
                v-model="agencyFilterValue"
                :custom-label="selectName"
                open-direction="bottom"
                :show-no-results="false"
                :options="agencyFilterList">
            </multiselect>
          </b-field>
        </div>
        <div class="column is-3" v-if="user.userType !== userTypes.AGENT">
          <b-field>
            <multiselect
                :placeholder="$t('agent')"
                v-model="agentFilterValue"
                :custom-label="selectName"
                open-direction="bottom"
                :show-no-results="false"
                :options="agentFilterList">
            </multiselect>
          </b-field>
        </div>

      </div>
      <div class="columns">
        <div class="column is-3" v-if="user.userType === userTypes.MANAGER">
          <b-field>
            <multiselect
                :placeholder="$t('dealer')"
                v-model="dealerFilterValue"
                :custom-label="selectName"
                open-direction="bottom"
                :show-no-results="false"
                :options="dealerFilterList">
            </multiselect>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field>
            <b-input :placeholder="$t('note')"
                     v-model="noteFilterValue"
                     custom-class="custom-search full-input"
                     icon-clickable
            >
            </b-input>
          </b-field>
        </div>

        <div class="column is-3">
          <b-field>
            <multiselect
                :placeholder="$t('currency')"
                v-model="currencyFilterValue"
                open-direction="bottom"
                :show-no-results="false"
                :options="currencyFilterList">
            </multiselect>
          </b-field>
        </div>
        <div class="column is-3">
          <div class="is-flex is-justify-content-flex-end">
            <b-button @click="getInvoices(desserts.currentPage,desserts.itemsPerPage)" class="btn btn-search"
                      type="is-primary">
              {{ $t('search') }}
            </b-button>
          </div>
        </div>

      </div>
    </template>
    <b-table
        :data="desserts.data"
        ref="table"
        :paginated="false"
        per-page="5"
        detailed
        detail-key="id"
        :show-detail-icon="false"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
    >

      <b-table-column field="id" :label="$t('nro')" width="40" numeric v-slot="props">
        <span class="col id">
           #{{ props.row.transactionNumber }}
        </span>
      </b-table-column>

      <b-table-column field="issuer" :label="$t('issuer')" sortable v-slot="props">
        <template>
          <a class="col details" @click="props.toggleDetails(props.row)">
            {{ fullName(props.row.sender) }}
          </a>
        </template>
      </b-table-column>

      <b-table-column field="recipient" :label="$t('recipient')" sortable v-slot="props">
        <span class="col dest">
           {{ fullName(props.row.recipent) }}
        </span>
      </b-table-column>
      <b-table-column field="agency" :label="`${$t('agency')}|${$t('agent')}`"
                      sortable v-slot="props">
        <span class="col agent" v-if="props.row.agencyName && props.row.agentName">
           <span>{{ props.row.agencyName }}/{{ props.row.agentName }}</span>
        </span>
        <span class="col agency" v-else-if="!props.row.agentName && props.row.agencyName">
           <span>{{ props.row.agencyName }}</span>
        </span>
        <span class="col manager" v-else>
          <span>{{ $t('manager') }}</span>
        </span>
      </b-table-column>
      <b-table-column field="date" :label="$t('date')" sortable v-slot="props">
        <span class="col date">
           {{ formatYYYYMMDD(props.row.date) }}
        </span>
      </b-table-column>
      <b-table-column field="Enviado" :label="$t('amount')" sortable v-slot="props">
        <span class="col cash">
          {{ props.row.amount }} {{ props.row.currency }}
        </span>
      </b-table-column>

      <b-table-column field="Estado" :label="$t('state')" sortable centered v-slot="props">
        <div class="cursor-pointer" @click="openModalStatus(props.row)">
          <b-tag type="is-tag-success success" rounded v-if="props.row.transactionStatus===status.PENDING">
            <span class="text-status pending"> {{ $t('pending') }}</span>
          </b-tag>
          <b-tag type="is-danger" rounded v-if="props.row.transactionStatus===status.CANCELED">
            <span class="text-status is-uppercase"> {{ $t('canceled') }}</span>
          </b-tag>
          <b-tag type="is-purple" rounded v-if="props.row.transactionStatus===status.COMPLETED">
            <span class="text-status is-uppercase"> {{ $t('delivered') }}</span>
          </b-tag>
          <b-tag type="is-green-success" rounded v-if="props.row.transactionStatus===status.SHIPPING">
            <span class="text-status is-uppercase"> {{ $t('distribution') }}</span>
          </b-tag>
          <b-tag type="is-danger" rounded v-if="props.row.transactionStatus===status.ERROR">
            <span class="text-status is-uppercase"> {{ $t('error') }}</span>
          </b-tag>
        </div>

      </b-table-column>

      <b-table-column :label="$t('actions')" v-slot="props">
        <div class="is-flex">
          <div class="mx-10" v-if="props.row.transactionStatus === status.PENDING && user.userType === userTypes.DEALER"
               @click="changeToShipping(props.row.id)">
            <b-tooltip type="is-danger" :label="$t('to_distribute')">
              <b-icon type="is-danger" icon="arrow-all" class="mx-4 cursor-pointer"></b-icon>
            </b-tooltip>
          </div>
          <div class="mx-10" v-if="props.row.transactionStatus === status.COMPLETED" @click="setPropsModals(props.row)">
            <b-tooltip type="is-purple" :label="$t('verify')">
              <b-icon type="is-purple" icon="ticket-account" class="mx-4 cursor-pointer"></b-icon>
            </b-tooltip>
          </div>
          <div @click="generateReport(props.row)" class="mx-10">
            <b-tooltip type="is-green-accent" :label="$t('print')">
              <b-icon type="is-green-accent" icon="printer" class="mx-4 cursor-pointer"></b-icon>
            </b-tooltip>
          </div>
          <div v-show="false" class="mx-10" @click="editRemittance(props.row)"
               v-if="props.row.transactionStatus === status.PENDING && user.userType !== userTypes.DEALER">
            <b-tooltip type="is-green-accent" :label="$t('edit')">
              <b-icon type="is-green-accent" icon="pencil" class="mx-4 cursor-pointer"></b-icon>
            </b-tooltip>
          </div>
          <div class="mx-10" hidden
               v-if="props.row.transactionStatus === status.PENDING  && user.userType === userTypes.MANAGER"
               @click="isModalCancelActive = !isModalCancelActive">
            <b-icon type="is-pink-light" icon="close-circle-outline cursor-pointer" class="mx-4"></b-icon>
          </div>
          <div class="mx-10" @click="props.toggleDetails(props.row)">
            <b-tooltip type="is-primary" :label="$t('details')">
              <b-icon type="is-primary" icon="launch" custom-class="mdi-rotate-90 cursor-pointer" class="mx-4"></b-icon>
            </b-tooltip>
          </div>
        </div>

      </b-table-column>

      <template #detail="props">
        <div class="columns">
          <div class="column is-4 is-flex is-flex-direction-column">
            <div class="is-flex is-align-items-center">
              <b-icon type="is-pink-light" icon="account-circle" class="mx-4"></b-icon>
              <span class="title-note-invoice">{{ $t('invoice_remittance') }}</span>
            </div>
            <div class="mt-20" v-if="props.row">
              <div class="columns">
                <div class="column">
                  <div class="is-flex is-flex-direction-column note">
                    <span class="note-title">{{ $t('recipient') }}:</span>
                    <span class="note-content">{{fullName(props.row.recipent)}}</span>

                  </div>
                  <div class="is-flex is-flex-direction-column note">
                    <span class="note-title">{{ $t('ci') }}:</span>
                    <span class="note-content">{{props.row.recipent.identificationNumber}}</span>
                  </div>
                  <div class="is-flex is-flex-direction-column note">
                    <span class="note-title">{{ $t('phone') }}:</span>
                    <span class="note-content">{{ props.row.recipent.phoneNumber }}</span>
                  </div>
                  <div class="is-flex is-flex-direction-column note">
                    <span class="note-title">{{ $t('card') }}:</span>
                    <span class="note-content">{{ props.row.mlcCardNumber }}</span>
                  </div>
                  <div class="is-flex is-flex-direction-column note">
                    <span class="note-title">{{ $t('province') }}:</span>
                    <span class="note-content">{{ props.row.recipent.province }}
                    </span>
                  </div>
                  <div class="is-flex is-flex-direction-column note">
                    <span class="note-title">{{ $t('municipality') }}:</span>
                    <span class="note-content">{{ props.row.recipent.municipality }}
                    </span>
                  </div>
                  <div class="is-flex is-flex-direction-column note">
                    <span class="note-title">{{ $t('address') }}:</span>
                    <span class="note-content">{{ props.row.recipent.addressLine }}
                    </span>
                  </div>
                </div>
                <div class="column">
                  <div class="is-flex is-flex-direction-column note">
                    <span class="note-title">{{ $t('issuer') }}:</span>
                    <span class="note-content">{{fullName(props.row.sender)}}</span>
                  </div>
                  <div class="is-flex is-flex-direction-column note">
                    <span class="note-title">{{ $t('phone') }}:</span>
                    <span class="note-content">{{ props.row.sender.phoneNumber }}</span>
                  </div>
                  <div class="is-flex is-flex-direction-column note">
                    <span class="note-title">{{ $t('state') }}:</span>
                    <span class="note-content">{{ props.row.sender.province }}
                    </span>
                  </div>
                  <div class="is-flex is-flex-direction-column note">
                    <span class="note-title">{{ $t('city') }}:</span>
                    <span class="note-content">{{ props.row.sender.municipality }}
                    </span>
                  </div>
                  <div class="is-flex is-flex-direction-column note">
                    <span class="note-title">{{ $t('address') }}:</span>
                    <span class="note-content">{{ props.row.sender.addressLine }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-4 is-flex is-flex-direction-column">
            <div class="is-flex is-align-items-center">
              <b-icon type="is-pink-light" icon="pencil" class="mx-4"></b-icon>
              <span class="title-note-invoice">{{ $t('invoice_note') }}</span>
            </div>
            <div class="mt-20">
              <b-field>
                <b-input type="textarea"
                         v-model="props.row.details"
                         custom-class="invoice-note has-fixed-size"
                         minlength="1"
                         maxlength="400"
                         :placeholder="$t('invoice_note')">
                </b-input>
              </b-field>
            </div>
            <div class="is-flex is-justify-content-flex-end">
              <b-button @click="saveDetails(props.row)" class="btn btn-save is-uppercase" type="is-primary">
                {{ $t('save') }}
              </b-button>
            </div>
          </div>
          <div class="column is-4 is-flex is-flex-direction-column">
            <div class="is-flex is-align-items-center">
              <b-icon type="is-pink-light" icon="file-document" class="mx-4"></b-icon>
              <span class="title-note-invoice">{{ $t('invoice_logs') }}</span>
            </div>
            <div class="mt-20">
              <div class="is-flex note">
                <span class="note-title">{{ $t('amount') }}:</span>
                <span class="note-content">{{ props.row.amount }}</span>
              </div>
              <div class="is-flex is-flex-direction-column note">
                <span class="note-title">{{ $t('currency') }}:</span>
                <span class="note-content">{{ props.row.currency }}</span>
              </div>

              <div class="is-flex is-flex-direction-column note" v-if="props.row.mlcCardNumber">
                <span class="note-title">{{ $t('card') }}:</span>
                <span class="note-content">{{ props.row.mlcCardNumber }}</span>
              </div>

              <div class="is-flex is-flex-direction-column note">
                <span class="note-title">{{ $t('total_to_pay') }}:</span>
                <span class="note-content">{{ props.row.amountPay.toFixed(2) }}</span>
              </div>
              <div class="is-flex is-flex-direction-column note">
                <span class="note-title">{{ $t('issuer') }}:</span>
                <span class="note-content">{{ props.row.sender.name }}-{{ props.row.sender.phoneNumber }}</span>
              </div>
              <div class="note">
                <h3 class="note-title">Logs</h3>
              </div>

              <b-tabs size="is-small" class="block">
                <b-tab-item v-for="(item, idx) in props.row.logs" :key="idx">
                  <template #header>
                    <span>{{ formatYYYYMMDD(item.dateCreate) }}</span>
                  </template>
                  <div class="is-flex note">
                    <span class="note-title">{{ $t('log_type') }}:</span>
                    <span class="note-content">{{ $t(getLogType(item.logType)) }}</span>
                  </div>
                  <div class="is-flex note">
                    <span class="note-title">{{ $t('modify_by') }}:</span>
                    <span class="note-content">{{ getModifyBy(item) }}</span>
                  </div>
                  <div class="is-flex note" v-if="item.changes.Amount">
                    <span class="note-title">{{ $t('amount_init') }}:</span>
                    <span class="note-content">{{ item.changes.Amount }} {{ item.currency }}</span>
                  </div>
                  <div class="is-flex note" v-if="item.changes.AmountPay">
                    <span class="note-title">{{ $t('amount_pay_init') }}:</span>
                    <span class="note-content">{{ item.changes.AmountPay }} USD</span>
                  </div>
                  <div class="is-flex note" v-if="item.dealer">
                    <span class="note-title">{{ $t('dealer') }}:</span>
                    <span class="note-content">{{ item.dealer.name }}</span>
                  </div>
                  <div class="is-flex note" v-if="item.changes.agencyId">
                    <span class="note-title">{{ $t('agency') }}:</span>
                    <span class="note-content">{{ item.changes.agencyId }}</span>
                  </div>
                  <div class="is-flex note" v-if="item.changes.agentId">
                    <span class="note-title">{{ $t('agent') }}:</span>
                    <span class="note-content">{{ item.changes.agentId }}</span>
                  </div>
                  <div class="is-flex note" v-if="item.changes.senderId">
                    <span class="note-title">{{ $t('issuer') }}:</span>
                    <span class="note-content">{{ item.changes.senderId }}</span>
                  </div>

                  <div class="is-flex note" v-if="item.amount">
                    <span class="note-title">{{ $t('amount_change') }}:</span>
                    <span class="note-content">{{ item.amount }} {{ item.currency }}</span>
                  </div>
                  <div class="is-flex note" v-if="item.amountPay">
                    <span class="note-title">{{ $t('amount_pay_change') }}:</span>
                    <span class="note-content">{{ item.amountPay.toFixed(2) }} USD</span>
                  </div>
                  <div class="is-flex note" v-if="item.changes.PaymentsGroup">
                    <span class="note-title">{{ $t('payment_group') }}:</span>
                    <span class="note-content">
                      {{ item.changes.PaymentsGroup }}
                    </span>
                  </div>
                  <div class="is-flex note" v-if="item.transactionStatusToName">
                    <span class="note-title">{{ $t('status') }}:</span>
                    <span class="note-content">{{ item.transactionStatusToName }}</span>
                  </div>
                  <div class="is-flex note" v-if="item.dateCreate">
                    <span class="note-title">{{ $t('dateCreate') }}:</span>
                    <span class="note-content">{{ formatYYYYMMDD(item.dateCreate) }}</span>
                  </div>
                  <div class="is-flex note" v-if="item.dateModificated">
                    <span class="note-title">{{ $t('dateModificated') }}:</span>
                    <span class="note-content">{{ formatYYYYMMDD(item.dateModificated) }}</span>
                  </div>
                </b-tab-item>
              </b-tabs>

            </div>
          </div>
        </div>

      </template>
    </b-table>
    <paginate
        :count="desserts.totalRecords"
        :per_page="desserts.itemsPerPage"
        :current-page="desserts.currentPage"
        :total-pages="desserts.totalPages"
        @first="getLast(true)"
        @previous="getInvoices(desserts.currentPage - 1,desserts.itemsPerPage)"
        @next="getInvoices(desserts.currentPage + 1,desserts.itemsPerPage)"
        @last="getLast"
        @perPage="getInvoices(desserts.currentPage,$event)"
        @search="getInvoices($event,desserts.itemsPerPage)"
    />
    <b-modal
        v-model="isModalRemittanceActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Example Modal"
        aria-modal>
      <template #default="props">
        <modal-new-remittance @reload="reload"
                              @close="props.close"></modal-new-remittance>
      </template>
    </b-modal>
    <b-modal
        v-model="isModalRemittanceEditActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Example Modal"
        aria-modal>
      <template #default="props">
        <modal-edit-remittance @reload="reload"
                               :edit="propsModalsEdit"
                               @close="props.close"></modal-edit-remittance>
      </template>
    </b-modal>
    <b-modal
        v-model="isModalActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Example Modal"
        aria-modal>
      <template #default="props">
        <modal-verify-remittance @reload="getInvoices(desserts.currentPage,desserts.itemsPerPage)" :item="propsModals"
                                 v-bind="propsModals"
                                 @close="props.close"></modal-verify-remittance>
      </template>
    </b-modal>
    <b-modal
        v-model="isModalCancelActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Example Modal"
        aria-modal>
      <template #default="props">
        <modal-cancel-remittance @close="props.close"></modal-cancel-remittance>
      </template>
    </b-modal>

    <b-modal
        v-model="isModalChangeStatusActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Example Modal"
        aria-modal>
      <template #default="props">
        <modal-change-status-remittance :remittance="changeStatus"
                                        @reload="getInvoices(desserts.currentPage,desserts.itemsPerPage)"
                                        @close="props.close"></modal-change-status-remittance>
      </template>
    </b-modal>
    <div>
      <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="false"
          :preview-modal="true"
          :paginate-elements-by-height="1400"
          :filename="filename"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          @hasDownloaded="setLoading(false)"
          @progress="onProgress($event)"
          @hasStartedGeneration="hasStartedGeneration()"
          @hasGenerated="hasGenerated($event)"
          ref="html2Pdf"
      >
        <section slot="pdf-content">
          <section class="is-flex is-justify-content-flex-start is-align-items-center px-50 mt-30">
            <div class="report-log">
              <img style="width: 80px;" :src="logo" alt="">
            </div>
            <div class=" is-flex">
              <div class="report-name">{{ nameReport }}</div>
            </div>
          </section>
          <section class="content-report px-50 mt-40">
            <div class="columns is-flex" v-if="remittance">
              <div class="column is-full is-flex is-flex-direction-column">
                <div class="is-flex">
                  <span class="report">{{ $t('invoice_number') }}:</span>
                  <span class="ml-15"> # {{ remittance.transactionNumber }}</span>
                </div>
                <div class="is-flex">
                  <span class="report">{{ $t('status') }}:</span>
                  <span class="ml-15">
                      <b-tag type="is-tag-success success" rounded v-if="remittance.transactionStatus===status.PENDING">
                        <span class="text-status pending"> {{ $t('pending') }}</span>
                      </b-tag>
                      <b-tag type="is-pink-light" rounded v-if="remittance.transactionStatus===status.CANCELED">
                        <span class="text-status is-uppercase"> {{ $t('canceled') }}</span>
                      </b-tag>
                      <b-tag type="is-purple" rounded v-if="remittance.transactionStatus===status.COMPLETED">
                        <span class="text-status is-uppercase"> {{ $t('delivered') }}</span>
                      </b-tag>
                      <b-tag type="is-green-success" rounded v-if="remittance.transactionStatus===status.SHIPPING">
                        <span class="text-status is-uppercase"> {{ $t('distribution') }}</span>
                      </b-tag>
                      <b-tag type="is-danger" rounded v-if="remittance.transactionStatus===status.ERROR">
                        <span class="text-status is-uppercase"> {{ $t('error') }}</span>
                      </b-tag></span>
                </div>
                <div class="is-flex">
                  <span class="report"> {{ $t('issuer') }}:</span>
                  <span class="ml-15">{{ remittance.sender.name }}</span>
                </div>
                <div class="is-flex">
                  <span class="report">{{ $t('recipient') }}:</span>
                  <span class="ml-15">{{ remittance.recipent.name }}</span>
                </div>
              </div>
            </div>

            <div class="columns is-flex " v-if="remittance">
              <div class="column is-full is-flex is-flex-direction-column">
                <div class="head-report">
                  <span class="report">{{ $t('total_to_paying') }}: </span>
                  <span class="ml-15">{{ remittance.amountPay }} USD</span>
                </div>
                <div class="head-report">
                  <span class="report">{{ $t('total_to_send') }}:</span>
                  <span class="ml-15">{{ remittance.amount }} {{ remittance.currency }}</span>
                </div>
                <div class="head-report">
                  <span class="report">{{ $t('address_send') }}:</span>
                  <span class="ml-15">
                        {{ remittance.recipent.province }},
                      {{ remittance.recipent.municipality }},
                      {{ remittance.recipent.addressLine }}
                      </span>
                </div>
                <div class="head-report">
                  <span class="report">{{ $t('phone') }}:</span>
                  <span class="ml-15">{{ remittance.recipent.phoneNumber }}</span>
                </div>
              </div>
            </div>
          </section>
          <section class="documents-report px-50" v-if="remittance && remittance.transactionStatus===status.COMPLETED">
            <div class="columns is-flex">
              <div class="column is-full is-flex">
                <div class="documents is-flex is-flex-direction-column">
                  <div class="title-label firm">{{ $t('evidences') }}</div>
                  <img class="dni" :src="remittance.documentImageUrl" alt="">
                </div>
<!--                <div class="documents ml-40 is-flex is-flex-direction-column">-->
<!--                  <div class="title-label firm">{{ $t('firm') }}</div>-->
<!--                  <img class="firm" :src="remittance.firmImageUrl" alt="">-->
<!--                </div>-->
              </div>
            </div>
          </section>
          <section class="mt-30 px-50">
            <div class="columns is-flex is-justify-content-space-between is-align-items-center">
              <div class="column" v-if="remittance">
                <div class="head-report">
                  <span class="report">{{ $t('agency') }}:</span>
                  <span class="ml-15">{{ remittance.agencyName }}</span>
                </div>
              </div>
              <div class="column is-flex is-flex-direction-column">
                <div class="title-label firm">{{ $t('firm_issuer') }}</div>
                <div class="mt-30 line_firm"></div>
              </div>
            </div>
          </section>
        </section>
      </vue-html2pdf>
    </div>
  </section>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'

import Breadcrumb from "@/components/structure/breadcrumb";
import surface from '@/assets/img/surface.png'
import addInvoice from '@/assets/img/add-invoice.png'
import ModalVerifyRemittance from "@/components/modals/modalVerifyRemittance";
import ModalCancelRemittance from "@/components/modals/modalCancelRemittance";
import {mapActions, mapGetters, mapMutations} from "vuex";
import ModalNewRemittance from "@/components/modals/modalNewRemittance";
import {USER_TYPE} from "@/enum/userType";
import ModalChangeStatusRemittance from "@/components/modals/modalChangeStatusRemittance";
import Paginate from "@/components/list/paginate";
import {TRANSACTION_STATUS} from "@/enum/transactionStatus";
import ModalEditRemittance from "@/components/modals/modalEditRemittance";
import {saveStorage} from "@/utils";
import logo from "@/assets/remisario/fullcolor/imagotipo.jpg";
import logotipo from "@/assets/img/logotipo.png";
import Multiselect from "vue-multiselect";
import {CURRENCY} from "@/enum/currency";

export default {
  name: "ListInvoiceComponent",
  components: {
    VueHtml2pdf,
    Multiselect,
    ModalEditRemittance,
    Paginate,
    ModalChangeStatusRemittance,
    ModalNewRemittance, ModalCancelRemittance, ModalVerifyRemittance, Breadcrumb
  },
  data() {
    return {
      search: null,
      filename: 'remittance',
      filter_name: 'filter-outline',
      remittance: null,
      nameReport: 'Factura de Remesa',
      surface, addInvoice, logo, logotipo,
      isModalActive: false,
      isModalCancelActive: false,
      isModalRemittanceActive: false,
      isModalRemittanceEditActive: false,
      isModalChangeStatusActive: false,
      paymentGroup: null,
      propsModals: {},
      propsModalsEdit: null,
      changeStatus: null,
      userTypes: USER_TYPE,
      status: TRANSACTION_STATUS,
      selectRangeDate: {
        start: '',
        end: ''
      },
      filter: false,
      desserts: [],
      defaultOpenedDetails: [1],
      query: '',
      transactionNumberValue: null,
      querys: {
        number: null,
        issuer: null,
        state: null,
        recipient: null,
        agency: null,
        agent: null,
        currency: null,
        dealer: null,
        date_init: null,
        date_end: null,
        all: null

      },
      issuerFilterList: [],
      issuerFilterValue: null,
      stateFilterValue: null,
      stateFilterList: [],
      recipientFilterList: [],
      recipientFilterValue: null,
      agencyFilterList: [],
      agencyFilterValue: null,
      agentFilterList: [],
      agentFilterValue: null,
      currencyFilterValue: null,
      currencyFilterList: [CURRENCY.CUP, CURRENCY.USD],
      dealerFilterList: [],
      dealerFilterValue: null,
      noteFilterValue: null,
      dateInit: null,
      dateEnd: null,
    }
  },
  methods: {
    ...mapMutations({
      setLoading: 'SET_LOADING'
    }),
    fullName(props){
      const lastName = props.lastName? props.lastName : ''
      const secondSurname = props.secondSurname ? props.secondSurname: ''
      return `${props.name} ${lastName} ${secondSurname}`
    },
    ...mapActions({
      acceptRemittance: 'ACCEPT_REMITTANCE',
      fetchInvoices: 'FETCH_INVOICE',
      updateDetails: 'UPDATE_INVOICE_DETAILS',
      fetchIssuers: 'FETCH_ISSUER',
      fetchRecipients: 'FETCH_RECIPIENT',
      fetchAgencies: 'FETCH_AGENCY',
      fetchAgents: 'FETCH_AGENT',
      fetchDealers: 'FETCH_DEALER'

    }),
    saveDetails(props) {
      let data = {
        id: props.id,
        details: props.details
      }
      this.updateDetails(data)
    },
    selectName({name}) {
      return name
    },
    onProgress(event) {
      console.log(event);
    },
    hasGenerated(event) {
      console.log(event);
    },
    hasStartedGeneration() {
    },
    generateReport(props) {
      this.remittance = props
      this.filename = 'remittance'
      this.$refs.html2Pdf.generatePdf()
    },
    getLast(isFirst = false) {
      let page = isFirst ? 1 : this.desserts.totalPages
      this.getInvoices(page, this.desserts.itemsPerPage)
    },
    getQuery() {
      return new Promise(resolve => {
        if (this.querys.number) {
          this.query += this.querys.number
        }
        if (this.querys.date_end) {
          this.query += this.querys.date_end
        }
        if (this.querys.date_init) {
          this.query += this.querys.date_init
        }
        if (this.querys.currency) {
          this.query += this.querys.currency
        }
        if (this.querys.state) {
          this.query += this.querys.state
        }
        if (this.querys.dealer) {
          this.query += this.querys.dealer
        }
        if (this.querys.agent) {
          this.query += this.querys.agent
        }
        if (this.querys.agency) {
          this.query += this.querys.agency
        }
        if (this.querys.recipient) {
          this.query += this.querys.recipient
        }
        if (this.querys.issuer) {
          this.query += this.querys.issuer
        }
        if (this.querys.all) {
          this.query += this.querys.all
        }
        resolve(this.query)
      })
    },
    changeToShipping(id) {
      let data = {
        remittanceId: id,
      }
      this.acceptRemittance(data)
          .then(() => {
            this.getInvoices(this.desserts.currentPage, this.desserts.itemsPerPage)
          })
    },
    getInvoices(current = 1, record = 10, id = null) {
      this.query = ''
      this.getQuery().then(query => {
        let data = {
          currentPage: current,
          recordsPerPage: record,
          query: query.length ? query : null,
          id: id,
        };
        this.fetchInvoices(data)
            .then(value => {
              this.desserts = value
            })
      })

    },
    reload() {
      window.location.reload();
    },
    getLogType(val) {
      if (val === 1) {
        return 'log_type_data'
      } else {
        return 'log_type_status'
      }

    },
    getModifyBy(item) {
      if (this.user.id === item.modificatedBy.id || this.user.userType === USER_TYPE.MANAGER) {
        return item.modificatedBy.name
      }
      if (item.userType === USER_TYPE.DEALER && (this.user.userType === USER_TYPE.AGENCY || this.user.userType === USER_TYPE.AGENT)) {
        return this.lang === 'es' ? 'Distribuidor' : 'Dealer'
      }
      if (item.userType === USER_TYPE.AGENT && (this.user.userType === USER_TYPE.AGENCY || this.user.userType === USER_TYPE.AGENT)) {
        return this.lang === 'es' ? 'Agente' : 'Agent'
      }
      if (item.userType === USER_TYPE.AGENCY && (this.user.userType === USER_TYPE.AGENCY || this.user.userType === USER_TYPE.AGENT)) {
        return this.lang === 'es' ? 'Agencia' : 'Agency'
      }
    },
    setPropsModals(newProp) {
      this.propsModals = newProp
      this.isModalActive = true
    },
    openModalRemittance() {
      this.isModalRemittanceActive = !this.isModalRemittanceActive
    },
    editRemittance(prop) {
      this.propsModalsEdit = prop
      saveStorage('editRemittance', prop)
      this.isModalRemittanceEditActive = !this.isModalRemittanceEditActive
    },
    openModalStatus(prop) {
      this.changeStatus = prop
      if (prop.transactionStatus !== TRANSACTION_STATUS.COMPLETED && prop.transactionStatus !== TRANSACTION_STATUS.CANCELED) {
        this.isModalChangeStatusActive = !this.isModalChangeStatusActive
      }
    },
    formatYYYYMMDD(value) {
      const date = new Date(value)
      if (value && !isNaN(date)) {
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        return year + '-' +
            ((month < 10 ? '0' : '') + month) + '-' +
            ((day < 10 ? '0' : '') + day)
      }
      return ''
    },
    formatHHMMSS(value) {
      const date = new Date(value)
      if (value && !isNaN(date)) {
        const hours = date.getHours()
        const minutes = date.getMinutes()
        const seconds = date.getSeconds()
        return this.formatNumber(hours, true) + ':' +
            this.formatNumber(minutes, true) + ':' +
            this.formatNumber(seconds, true)
      }
      return ''
    },
    getIssuers(current = 1, record = 0) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: null,
      };
      this.fetchIssuers(data).then(value => this.issuerFilterList = value.data)
    },
    getRecipients(current = 1, record = 0) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: null,
      };
      this.fetchRecipients(data).then(value => this.recipientFilterList = value.data)
    },
    getAgencies(current = 1, record = 0) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: null,
      };
      this.fetchAgencies(data).then(value => this.agencyFilterList = value.data)
    },
    getAgents(current = 1, record = 0) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: null,
      };
      this.fetchAgents(data).then(value => this.agentFilterList = value.data)
    },
    getDealers(current = 1, record = 0) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: null,
      };
      this.fetchDealers(data).then(value => this.dealerFilterList = value.data)
    },
    searchIconClick() {
      let filter = this.search;
      this.querys.all = `&query=${filter}`
      this.getInvoices(1, 10)
    },
  },
  watch: {
    search: function () {
      if (!this.search) {
        this.querys.all = null
        this.getInvoices(1, 10)
      }
    },
    filter: function () {
      this.filter_name = this.filter ? 'filter-remove-outline' : 'filter-outline'
    },
    issuerFilterValue: function () {
      this.querys.issuer = this.issuerFilterValue ? `&senderQuery=${this.issuerFilterValue.name}` : null
    },
    transactionNumberValue: function () {
      this.querys.number = this.transactionNumberValue.length ? `&transactionNumber=${this.transactionNumberValue}` : null
    },
    recipientFilterValue: function () {
      this.querys.recipient = this.recipientFilterValue ? `&receiverQuery=${this.recipientFilterValue.name}` : null
    },
    agencyFilterValue: function () {
      this.querys.agency = this.agencyFilterValue ? `&agencyQuery=${this.agencyFilterValue.name}` : null
    },
    agentFilterValue: function () {
      this.querys.agent = this.agentFilterValue ? `&agentQuery=${this.agentFilterValue.name}` : null
    },
    dealerFilterValue: function () {
      this.querys.dealer = this.agentFilterValue ? `&dealerId=${this.dealerFilterValue.id}` : null
    },
    stateFilterValue: function () {
      this.querys.state = this.stateFilterValue ? `&status=${this.stateFilterValue}` : null
    },
    currencyFilterValue: function () {
      this.querys.currency = this.currencyFilterValue ? `&currency=${this.currencyFilterValue}` : null
    },
    noteFilterValue: function () {
      // if (this.query !== null) {
      //   this.query += `&currency=${this.noteFilterValue}`
      // } else {
      //   this.query = `?currency=${this.noteFilterValue}`
      // }
    },
    dateInit: function () {
      this.querys.date_init = this.dateInit ? `&InitDate=${this.dateInit}` : null
    },
    dateEnd: function () {
      this.querys.date_end = this.dateEnd ? `&FinalDate=${this.dateEnd}` : null
    },

  },
  computed: {
    ...mapGetters({
      user: 'GET_USER',
    })
  },
  created() {
    this.getInvoices(1, 10)
    if (this.user.userType !== USER_TYPE.DEALER && this.user.userType !== USER_TYPE.DELIVERYMAN) {
      this.getRecipients()
      this.getIssuers()
    }

    this.getAgencies()
    this.getAgents()
    if (this.user.userType === USER_TYPE.MANAGER) {
      this.getDealers()

    }
  }
}
</script>

<style scoped lang="scss">

.col {
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #0B2B46;

  &.id {
    font-weight: 900;
    font-size: 20px;
    text-align: center;
    color: #C1C1C1;
  }

  &.details {
    font-weight: bold;
    font-size: 16px;
    color: #666BAA;
  }

  &.manager {
    font-weight: bold;
    font-size: 16px;
    color: #4D5EC3;
  }

  &.dest {
    font-weight: normal;
    font-size: 16px;
  }

  &.agency {
    font-weight: bold;
    font-size: 16px;
    color: #28BCE8;
  }

  &.agent {
    font-weight: bold;
    font-size: 16px;
    color: #0B2B46;
  }

  &.date {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
  }

  &.cash {
    font-weight: 900;
    font-size: 20px;
    text-align: center;
  }
}

.text-status {
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.5px;
  font-weight: bold;

  &.pending {
    color: #289BA4;
  }

}

.btn {
  margin-top: 0;
  align-items: center;
  padding: 19px 32px;
  position: absolute;
  border-radius: 10px;
  height: 48px;
  font-size: 15px;
  line-height: 32px;
  letter-spacing: 0.5px;
  font-weight: normal;

  &.btn-search {
    background: #0F2CE0;
    width: 327px;
    color: #FFFFFF;
  }

  &.btn-save {
    background: #0F2CE0;
    width: 120px;
    color: #FFFFFF;
    height: 30px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
  }
}

.title-note-invoice {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.5px;
}

.note {
  margin: 3px 0;
  font-style: normal;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.5px;

  .note-title {
    font-weight: bold;
    color: #0B2B46;
  }

  .note-content {
    margin-left: 10px;
    font-weight: normal;
    color: #6E7191;

    &.primary {
      color: #666BAA;
    }
  }
}

.report-name {
  text-align: center;
  font-weight: normal;
  color: #0B2B46;
  font-size: 28px;
}

.report {
  color: #0B2B46;
  font-weight: bold;
}

.documents {
  .dni {
    height: auto;
    max-width: 300px;
    border-radius: 10px;
  }

  .firm {
    height: auto;
    max-width: 250px;
  }
}

.line_firm {
  border-bottom: 1px solid;
  width: 200px;
}

.tag:not(body).is-rounded {
  border-radius: 290486px;
  min-width: 125px;
}
</style>