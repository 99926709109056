<template>
  <div class="modal-card modal-form rounded-10">
    <section class="modal-card-body">
      <div class="content">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="title-new-modal">{{ $t('remittance_edit') }}</div>
          <button @click="close" class="delete" aria-label="close"></button>
        </div>
        <section class="pl-20 pr-10 pt-15">
          <div class="card rounded-10 pa-10">
            <div class="columns card rounded-10 mt-30 pa-10 is-flex is-flex-direction-column">
              <div class="columns">
                <div class="column is-10">
                  <b-field>
                    <multiselect
                        :placeholder="$t('issuer')"
                        v-model="issuer"
                        :custom-label="selectNameIssuer"
                        :searchable="true"
                        open-direction="bottom"
                        :show-no-results="false"
                        :options="issuerList">
                    </multiselect>
                  </b-field>
                </div>
                <div class="column is-2">
                  <button @click="isModalIssuerActive= !isModalIssuerActive"
                          class="button new is-pink-light">
                    <span class="mdi mdi-pencil"></span>
                  </button>
                </div>
              </div>
              <div class="columns">
                <div class="column is-10">
                  <b-field class="mt-10">
                    <multiselect
                        :placeholder="$t('addressee')"
                        v-model="addressee"
                        :custom-label="selectName"
                        open-direction="bottom"
                        :show-no-results="false"
                        :options="addresseeList">
                    </multiselect>
                  </b-field>
                </div>
                <div class="column is-2">
                  <button @click="isModalRecipientActive= !isModalRecipientActive"
                          class="button new is-pink-light">
                    <span class="mdi mdi-pencil"></span>
                  </button>
                </div>
              </div>

            </div>
            <b-tabs type="is-boxed">
              <b-tab-item v-if="currency === 'USD'">
                <template #header>
                  <span class="tag is-green-success mx-10 rounded-10">USD</span>
                </template>
                <div class="text-span">{{ $t('text_remittance', {percentage: fee_USD}) }}</div>
                <div class="is-flex is-align-items-center is-justify-content-space-between">
                  <div class="text-amount">{{ $t('total_amount') }}</div>
                  <div>
                    <b-field custom-class="custom-field-label">
                      <b-input custom-class="amount-remittance" v-model="remittance_USD.amount"
                               type="number"
                               placeholder="0"></b-input>
                    </b-field>
                  </div>
                  <div class="is-flex is-flex-direction-column">
                    <span class="text-fee-delivery" v-if="!amountPayShow_USD">{{ $t('delivery_remittance') }}</span>
                    <span class="text-fee-delivery" v-else>{{ $t('delivery_remittance_value') }} {{
                        amountPayShow_USD
                      }}</span>
                    <span class="text-fee-delivery" v-if="!fee_remittance_USD">{{
                        $t('delivery_remittance_fee')
                      }}</span>
                    <span class="text-fee-delivery" v-else>{{ $t('delivery_remittance_fee_value') }} {{
                        fee_remittance_USD
                      }}</span>
                  </div>
                </div>
                <div class="mt-20">
                  <b-field>
                    <b-input v-model="remittance_USD.details" rounded maxlength="200" type="textarea"></b-input>
                  </b-field>
                </div>
              </b-tab-item>
              <b-tab-item v-if="currency==='CUP'">
                <template #header>
                  <span class="tag is-green-success mx-10 rounded-10">CUP</span>
                </template>
                <div class="text-span">{{ $t('text_remittance', {percentage: fee_CUP}) }}</div>
                <div class="is-flex is-align-items-center is-justify-content-space-between">
                  <div class="text-amount">{{ $t('total_amount') }}</div>
                  <div>
                    <b-field custom-class="custom-field-label">
                      <b-input custom-class="amount-remittance" v-model="remittance_CUP.amount"
                               type="number"
                               placeholder="0"></b-input>
                    </b-field>
                  </div>
                  <div class="is-flex is-flex-direction-column">
                    <span class="text-fee-delivery" v-if="!amountPayShow_CUP">{{ $t('delivery_remittance') }}</span>
                    <span class="text-fee-delivery" v-else>{{ $t('delivery_remittance_value') }} {{
                        amountPayShow_CUP
                      }}</span>
                    <span class="text-fee-delivery" v-if="!fee_remittance_CUP">{{
                        $t('delivery_remittance_fee')
                      }}</span>
                    <span class="text-fee-delivery" v-else>{{ $t('delivery_remittance_fee_value') }} {{
                        fee_remittance_CUP
                      }}</span>
                  </div>
                </div>
                <div class="mt-20">
                  <b-field>
                    <b-input v-model="remittance_CUP.details" rounded maxlength="200" type="textarea"></b-input>
                  </b-field>
                </div>
              </b-tab-item>
            </b-tabs>

          </div>
          <div class="buttons mt-40 is-flex is-align-items-center is-justify-content-flex-end">
            <button @click="save" class="button save is-primary">{{ $t('save') }}</button>
          </div>
          <b-modal
              v-model="isModalIssuerActive"
              has-modal-card
              trap-focus
              :destroy-on-hide="false"
              aria-role="dialog"
              aria-label="Example Modal"
              aria-modal>
            <template #default="props">
              <modal-new-issuer @refresh="refreshIssuer" @close="props.close"></modal-new-issuer>
            </template>
          </b-modal>
          <b-modal
              v-model="isModalRecipientActive"
              has-modal-card
              trap-focus
              :destroy-on-hide="false"
              aria-role="dialog"
              aria-label="Example Modal"
              aria-modal>
            <template #default="props">
              <modal-new-recipient @refresh="refreshDealer" @close="props.close"></modal-new-recipient>
            </template>
          </b-modal>
        </section>

      </div>
    </section>
  </div>
</template>

<script>

import ModalNewIssuer from "@/components/modals/modalNewIssuer";
import ModalNewRecipient from "@/components/modals/modalNewRecipient";
import Multiselect from "vue-multiselect";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {USER_TYPE} from "@/enum/userType";
import {getStorage} from "@/utils";
import {CURRENCY} from "@/enum/currency";

export default {
  name: "modalEditRemittance",
  components: {ModalNewIssuer, ModalNewRecipient, Multiselect},
  props: ['edit'],
  data() {
    return {
      isModalIssuerActive: false,
      isModalRecipientActive: false,
      addressee: null,
      issuer: null,
      currency: null,
      nameRecipient: null,
      paymentGroupsValueUSD: null,
      paymentGroupsValueCUP: null,
      addresseeList: [],
      issuerList: [],
      agencyList: [],
      agentList: [],
      received: null,
      fee_USD: null,
      fee_remittance_USD: null,
      fee_CUP: null,
      fee_remittance_CUP: null,
      amountPayShow_USD: null,
      amountPayShow_CUP: null,
      paymentsGroupsList: [],
      agency: null,
      agent: null,
      userTypes: USER_TYPE,
      remittancesPost: [],
      remittanceId: null,
      remittance_USD: {
        address: null,
        senderId: null,
        details: null,
        agencyId: null,
        agentId: null,
        amount: null,
        recipentId: null,
        currency: CURRENCY.USD,
        paymentsGroup: null,
        amountPay: null
      },
      remittance_CUP: {
        address: null,
        senderId: null,
        details: null,
        agencyId: null,
        agentId: null,
        amount: null,
        recipentId: null,
        currency: CURRENCY.CUP,
        paymentsGroup: null,
        amountPay: null
      },
      remittance_EUR: {
        address: null,
        senderId: null,
        details: null,
        agencyId: null,
        agentId: null,
        amount: null,
        recipentId: null,
        currency: CURRENCY.CUP,
        paymentsGroup: null,
        amountPay: null
      }

    }
  },
  computed: {
    ...mapGetters({
      currencyList: 'GET_CURRENCIES',
      user: 'GET_USER',
      paymentGroupUSD: 'GET_PAYMENTS_GROUPS_REMITTANCES_USD',
      paymentGroupCUP: 'GET_PAYMENTS_GROUPS_REMITTANCES_CUP',
      paymentGroupEUR: 'GET_PAYMENTS_GROUPS_REMITTANCES_EUR'

    })
  },
  methods: {
    ...mapActions({
      fetchReceived: 'FETCH_RECIPIENT',
      fetchReceivedBySender: 'FETCH_RECIPIENT_BY_ISSUER',
      fetchAgency: 'FETCH_AGENCY',
      fetchAgent: 'FETCH_AGENT',
      fetchAgentByAgency: 'FETCH_AGENTS_BY_AGENCY',
      fetchSender: 'FETCH_ISSUER',
      fetchSenderByAgency: 'FETCH_ISSUER_BY_AGENCY',
      fetchPaymentsGroups: 'FETCH_PAYMENTS_GROUPS',
      fetchPaymentsGroupsRemittance: 'FETCH_INVOICE_PAYMENT_GROUP',
      fetchFees: 'FETCH_FEES_REMITTANCE',
      editInvoice: 'UPDATE_INVOICE'
    }),
    ...mapMutations({
      setPaymentGroupUSD: 'SET_PAYMENTS_GROUPS_REMITTANCES_USD',
      setPaymentGroupCUP: 'SET_PAYMENTS_GROUPS_REMITTANCES_CUP',
      setPaymentGroupEUR: 'SET_PAYMENTS_GROUPS_REMITTANCES_EUR'
    }),
    selectNameIssuer({name, phoneNumber}) {
      return `(${phoneNumber})--${name}`
    },
    selectName({name}) {
      return name
    },
    currencyFromTo({name, currencyFrom, currencyTo, rate}) {
      return `${name} - ${currencyFrom} — ${currencyTo} - (${rate}%)`
    },
    save() {
      if (this.remittance_USD.amount) {
        this.remittance_USD.amount = Number(this.remittance_USD.amount)
        this.remittance_USD.amountPay = Number(this.remittance_USD.amountPay)
        this.remittancesPost.push(this.remittance_USD)
      }
      if (this.remittance_CUP.amount) {
        this.remittance_CUP.amount = Number(this.remittance_CUP.amount)
        this.remittance_CUP.amountPay = Number(this.remittance_CUP.amountPay)
        this.remittancesPost.push(this.remittance_CUP)
      }
      if (this.remittance_EUR.amount) {
        this.remittance_EUR.amount = Number(this.remittance_EUR.amount)
        this.remittance_EUR.amountPay = Number(this.remittance_EUR.amountPay)
        this.remittancesPost.push(this.remittance_CUP)
      }

      switch (this.currency) {
        case CURRENCY.USD:
          this.remittanceId = this.remittance_USD.id
          break;
        case CURRENCY.CUP:
          this.remittanceId = this.remittance_CUP.id
          break;
        case CURRENCY.EUR:
          this.remittanceId = this.remittance_EUR.id
      }
      let data = {
        remittance: this.remittancesPost[0],
        id: this.remittanceId
      }
      this.editInvoice(data)
          .then(() => {
            this.close()
          })

    },
    close() {
      this.$emit('close')
      this.$emit('reload')
    },
    refreshIssuer() {
      if (this.agency) {
        this.fetchSenderByAgency(this.agency.id)
            .then(value => {
              this.issuerList = value.data
            })
      } else {
        this.fetchSender()
            .then(value => {
              this.issuerList = value.data
            })
      }

    },
    refreshDealer() {
      if (this.issuer) {
        this.fetchReceivedBySender(this.issuer.id)
            .then(value => {
              this.addresseeList = value.data
            })
      }

    },
    getPaymentGroup(currency) {
      let pg;
      switch (currency) {
        case CURRENCY.USD:
          pg = getStorage('paymentGroupsUSD')
          break;
        case CURRENCY.CUP:
          pg = getStorage('paymentGroupsCUP')
          break;
        case CURRENCY.EUR:
          pg = getStorage('paymentGroupsEUR')
          break;
      }

      return pg
    },
    calculateRemittanceUSD() {
      let pg = this.getPaymentGroup(CURRENCY.USD)
      this.remittance_USD.paymentsGroup = pg.id
      let query = {
        amount: this.remittance_USD.amount,
        paymentGroup: pg.id
      }
      this.fetchFees(query)
          .then(response => {
            this.remittance_USD.amountPay = response.usdAmountPay
            this.fee_remittance_USD = response.usdFee.toFixed(2)
            this.amountPayShow_USD = this.remittance_USD.amountPay.toFixed(2)
          }).catch(() => {
        this.remittance_USD.amountPay = null
        this.fee_remittance_USD = null
        this.amountPayShow_USD = null
      })
    },
    calculateRemittanceCUP() {
      let pg = this.getPaymentGroup(CURRENCY.CUP)
      this.remittance_CUP.paymentsGroup = pg.id
      let query = {
        amount: this.remittance_CUP.amount,
        paymentGroup: pg.id
      }
      this.fetchFees(query)
          .then(response => {
            this.remittance_CUP.amountPay = response.usdAmountPay
            this.fee_remittance_CUP = response.usdFee.toFixed(2)
            this.amountPayShow_CUP = this.remittance_CUP.amountPay.toFixed(2)
          }).catch(() => {
        this.remittance_CUP.amountPay = null
        this.fee_remittance_CUP = null
        this.amountPayShow_CUP = null
      })
    },
    calculateRemittanceEUR() {
      let pg = this.getPaymentGroup(CURRENCY.EUR)
      this.remittance_EUR.paymentsGroup = pg.id
      let query = {
        amount: this.remittance_EUR.amount,
        paymentGroup: pg.id
      }
      this.fetchFees(query)
          .then(response => {
            this.remittance_EUR.amountPay = response.usdAmountPay
            this.fee_remittance_EUR = response.usdFee.toFixed(2)
            this.amountPayShow_EUR = this.remittance_EUR.amountPay.toFixed(2)
          }).catch(() => {
        this.remittance_EUR.amountPay = null
        this.fee_remittance_EUR = null
        this.amountPayShow_EUR = null
      })

    },

    getIssuers(current = 1, record = 0) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: null,
      };
      return new Promise(resolve => {
        this.fetchSender(data)
            .then(value => {
              resolve(value)
            })
      })
    },

  },
  watch: {
    issuer: function () {
      this.remittance_USD.senderId = this.issuer.id
      this.remittance_CUP.senderId = this.issuer.id
      this.fetchReceivedBySender(this.issuer.id)
          .then(value => {
            this.addresseeList = value.data
          })
    },
    addressee: function () {
      this.remittance_USD.recipentId = this.addressee.id
      this.remittance_CUP.recipentId = this.addressee.id
    },
    paymentGroupsValueUSD: function () {
      this.setPaymentGroupUSD(this.paymentGroupsValueUSD)
      this.remittance_USD.paymentsGroup = this.paymentGroupsValueUSD.id
    },
    paymentGroupsValueCUP: function () {
      this.setPaymentGroupCUP(this.paymentGroupsValueCUP)
      this.remittance.paymentsGroup = this.paymentGroupsValueCUP.id
    },
    'remittance_USD.amount': function () {
      this.fee_USD = null
      this.fee_remittance_USD = null
      this.remittance_USD.amountPay = null
      this.calculateRemittanceUSD()
    },
    'remittance_CUP.amount': function () {
      this.fee_CUP = null
      this.fee_remittance_CUP = null
      this.remittance_CUP.amountPay = null
      this.calculateRemittanceCUP()
    },
  },
  created() {
    let edit = getStorage('editRemittance')
    if (edit) {
      this.fetchPaymentsGroupsRemittance(this.edit.id)
          .then(value => {
            value.forEach(pg => {
              if (pg.currencyTo === CURRENCY.CUP) {
                this.setPaymentGroupCUP(pg)
              } else {
                this.setPaymentGroupUSD(pg)
              }
            })
          })
      this.issuer = edit.sender
      this.addressee = edit.recipent
      this.currency = edit.paymentGroup.currencyTo
      if (this.currency === CURRENCY.CUP) {
        this.remittance_CUP.amount = edit.amount
        this.remittance_CUP.details = edit.details
        this.remittance_CUP.id = edit.id
        this.calculateRemittanceCUP()
      }
      if (this.currency === CURRENCY.USD) {
        this.remittance_USD.amount = edit.amount
        this.remittance_USD.details = edit.details
        this.remittance_USD.id = edit.id
        this.calculateRemittanceUSD()
      }

    }
    this.getIssuers()
        .then(value => {
          this.issuerList = value.data
        })
    this.fetchReceived().then(value => {
      this.addresseeList = value.data
    })
  }
}
</script>

<style lang="scss">
.button {
  &.new {
    width: 48px;
    height: 48px;
    border-radius: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 180%;
    letter-spacing: 0.5px;
    color: #FFFFFF;

    &:hover {
      color: #0B2B46;
    }

    &:active {
      color: #0B2B46;
    }

    .mdi-pencil {
      font-size: 38px;
    }
  }

  &.new-coin {
    width: 274px;
    height: 48px;
    font-weight: bold;
    font-size: 15px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: #666BAA;
    border: 1px solid #666BAA;;

    .mdi-edit {
      font-size: 38px;
    }
  }

  &.save {
    width: 274px;
    height: 48px;
    border-radius: 10px;
  }
}

.custom-field-label {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 32px !important;
  letter-spacing: 0.5px !important;
  color: #AEAEAE !important;
}

.currency {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #666BAA;
}

.text-span {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 32px;
  letter-spacing: 0.5px;
  margin-top: 20px;
  color: #AEAEAE;
}

.text-amount {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #AEAEAE;
}

.text-fee-delivery {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #0B2B46;
}
</style>