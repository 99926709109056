<template>
  <div class="modal-card" style="width: auto">

    <section class="modal-card-body">
      <div class="gradient"></div>
      <div class="content">
        <div class="is-flex height-75 is-justify-content-flex-end is-align-items-center">
          <div class="close-modal">
            <a @click="closed">
              <img :src="close" alt="">
            </a>
          </div>
        </div>
        <div class="columns">
          <div class="column personal-data is-3 is-flex is-flex-direction-column">
            <div><span class="name">{{ item.recipent.name }}</span><span class="name last"> </span>
            </div>
            <div v-if="item.recipent.identificationNumber" class="title-label">{{ $t('dni') }}</div>
            <div v-if="item.recipent.identificationNumber" class="data dni">{{ item.recipent.identificationNumber }}</div>
            <div v-if="item.mlcCardNumber" class="title-label">{{ $t('card') }}</div>
            <div v-if="item.mlcCardNumber" class="data">{{ item.mlcCardNumber }}</div>
            <div v-if="item.recipent.addressLine" class="title-label">{{ $t('address') }}</div>
            <div v-if="item.recipent.addressLine" class="data">{{ item.recipent.addressLine }}</div>
<!--            <div class="title-label firm">{{ $t('firm') }}</div>-->
<!--            <img class="picture-firm" :src="item.firmImageUrl" alt="">-->
          </div>
          <div class="column transfer-data is-9 is-flex is-flex-direction-column">
            <div class="columns">
              <div class="scan-dni">
                <img class="picture-dni" :src="item.documentImageUrl" alt="">
              </div>
            </div>
            <div class="columns mt-70">
              <div class="column box-received is-flex is-flex-direction-row is-align-items-center">
                <div class="title-label">{{ $t('received') }}:</div>
                <div class="received mx-10">{{ item.amount.toFixed(2) }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>

  </div>
</template>

<script>
import close from '@/assets/img/close.png'
import {mapGetters} from 'vuex'

export default {
  name: 'modalVerifyRemittance',
  data() {
    return {
      close,
    }
  },
  props: ['item'],
  computed: {
    ...mapGetters({
      relationship: 'GET_RELATIONSHIP_CHOICES'
    })
  },
  methods: {
    getRelationship(id) {
      let temp = this.relationship.find(r => r.value = id)
      return temp ? temp.name : ''
    },
    closed(){
      this.$emit('close')
      this.$emit('reload')
    }
  }
}
</script>

<style scoped lang="scss">
.picture-firm {
  max-height: 100px;
}
.picture-dni{
  max-height: 315px;
}
.modal-card-body {
  padding: 0 !important;

}

.close-modal {
  position: relative;
  top: -20px;
}

.title-label {
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.007em;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #666BAA;
}

.box-received {
  border: 1px solid #FDC4D1;
  border-radius: 10px;

  .received {
    font-weight: 600;
    font-size: 32px;
    line-height: 44px;
    letter-spacing: 0.007em;
    color: #0B2B46;
  }
}


.personal-data {
  padding: 0 !important;
  width: 230px;
  margin-left: 100px;
  font-weight: 600;

  .name {
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;

    &.last {
      font-weight: 800;
    }
  }


  .firm {
    margin-top: 19px;
  }

  .data {
    font-size: 22px;
    line-height: 30px;

    &.dni {
      font-weight: 800;
      font-size: 32px;
      line-height: 30px;
    }
  }
}

.transfer-data {
  width: 489px;
  margin-left: 90px;
  margin-right: 101px;
  height: 103px;
  box-sizing: border-box;
  border-radius: 10px;

  .title-label {
    color: #666BAA;
    margin-top: 10px !important;
  }


  .column {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .number-label {
    font-weight: 600;
    font-size: 32px;
    line-height: 30px;
    letter-spacing: 0.007em;
  }

}

.scan-dni {
  margin-top: 0px;
  height: 200px;
  width: 486px;
  border-radius: 10px;
  margin-bottom: 40px;

}

.modal .animation-content {
  width: 1010px;
  margin: 0 20px 0 300px;
}

.modal-card {
  width: 1010px !important;
}

.content {
  padding: 20px;
  position: relative;
  top: -100px;
}

.gradient {
  background: rgba(196, 196, 196, 0.4);
  border-bottom-right-radius: 100%;
  width: 123.42px;
  height: 120.78px;
  left: 0;
  top: 0;

}

/* (1366x768) WXGA Display */
@media screen and (max-width: 1366px) {
  /* insert styles here */
  .modal-card-body {
    padding: 0 !important;

  }

  .close-modal {
    position: relative;
    top: -40px;
  }

  .title-label {
    margin-top: 20px;
    font-size: 10px;
    line-height: 12px;
    margin-bottom: 5px;
  }

  .personal-data {
    padding: 0 !important;
    width: 230px;
    margin-left: 100px;

    .name {
      font-size: 18px;
      line-height: 12px;
    }


    .firm {
      margin-top: 19px;
    }

    .data {
      font-size: 14px;
      line-height: 12px;

      &.dni {
        font-size: 16px;
        line-height: 12px;
      }
    }
  }

  .transfer-data {
    width: 300px;
    margin-left: 90px;
    margin-right: 101px;
    height: 70px;
    border: 1px solid #666BAA;
    box-sizing: border-box;
    border-radius: 10px;

    .title-label {
      margin-top: 10px !important;
    }


    .column {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .number-label {
      font-weight: 600;
      font-size: 18px;
      line-height: 12px;
    }

  }

  .scan-dni {
    margin-top: 20px;
    height: 257px;
    width: 300px;
    border-radius: 10px;

  }

  .modal .animation-content {
    width: 800px;
    margin: 0 20px 0 300px;
  }

  .modal-card {
    width: 800px !important;
    margin-left: 200px !important;
  }

  .content {
    padding: 20px;
    position: relative;
    top: -100px;
  }

  .gradient {
    width: 123.42px;
    height: 120.78px;
    left: 0;
    top: 0;
    background: rgba(196, 196, 196, 0.4);
    border-bottom-right-radius: 100%;

  }
}
</style>
