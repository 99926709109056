<template>
  <div class="modal-card modal-form rounded-10">
    <section class="modal-card-body">
      <div class="content">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="title-new-modal">{{ $t('remittance') }}</div>
          <button @click="closed()" class="delete" aria-label="close"></button>
        </div>
        <section class="pl-20 pr-10 pt-15">
          <div class="card rounded-10 pa-10">
            <div class="columns card rounded-10 mt-30 pa-10 is-flex is-flex-direction-column">
              <div class="columns">
                <div class="column is-10">
                  <b-field v-if="user.userType === userTypes.MANAGER">
                    <multiselect
                        :placeholder="$t('agency')"
                        v-model="agency"
                        :custom-label="selectName"
                        open-direction="bottom"
                        :show-no-results="false"
                        :options="agencyList">
                    </multiselect>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column is-10">
                  <b-field v-if="user.userType === userTypes.MANAGER || user.userType===userTypes.AGENCY">
                    <multiselect
                        :placeholder="$t('agent')"
                        v-model="agent"
                        :custom-label="selectName"
                        open-direction="bottom"
                        :show-no-results="false"
                        :options="agentList">
                    </multiselect>
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column is-10">
                  <b-field>
                    <multiselect
                        :placeholder="$t('issuer')"
                        v-model="issuer"
                        :custom-label="selectNameIssuer"
                        :searchable="true"
                        open-direction="bottom"
                        :show-no-results="false"
                        :options="issuerList">
                    </multiselect>
                  </b-field>
                </div>
                <div class="column is-2">
                  <button @click="isModalIssuerActive= !isModalIssuerActive"
                          class="button new is-pink-light">
                    <span class="mdi mdi-plus"></span>
                  </button>
                </div>
              </div>
              <div class="columns">
                <div class="column is-10">
                  <b-field class="mt-10">
                    <multiselect
                        :placeholder="$t('addressee')"
                        v-model="addressee"
                        :custom-label="selectName"
                        open-direction="bottom"
                        :show-no-results="false"
                        :options="addresseeList">
                    </multiselect>
                  </b-field>
                </div>
                <div class="column is-2">
                  <button @click="isModalRecipientActive= !isModalRecipientActive"
                          class="button  new is-pink-light">
                    <span class="mdi mdi-plus"></span>
                  </button>
                </div>
              </div>
            </div>
            <b-tabs type="is-boxed">
              <b-tab-item v-if="has_usd">
                <template #header>
                  <span class="tag is-green-success mx-10 rounded-10">USD</span>
                </template>
                <div class="text-span d-none">{{ $t('text_remittance', {percentage: fee_USD}) }}</div>
                <div class="is-flex is-align-items-center is-justify-content-space-between">
                  <div class="text-amount">{{ $t('total_amount') }}</div>
                  <div>
                    <b-field custom-class="custom-field-label">
                      <b-input custom-class="amount-remittance" v-model="remittance_USD.amount"
                               type="number"
                               placeholder="0"></b-input>
                    </b-field>
                  </div>
                  <div class="is-flex is-flex-direction-column">
                    <span class="text-fee-delivery" v-if="!amountPayShow_USD">{{ $t('delivery_remittance') }}</span>
                    <span class="text-fee-delivery" v-else>{{ $t('delivery_remittance_value') }} {{
                        amountPayShow_USD
                      }}</span>
                    <span class="text-fee-delivery" v-if="!fee_remittance_USD">{{
                        $t('delivery_remittance_fee')
                      }}</span>
                    <span class="text-fee-delivery" v-else>{{ $t('delivery_remittance_fee_value') }} {{
                        fee_remittance_USD
                      }}</span>
                  </div>
                </div>
                <div class="mt-20">
                  <b-field>
                    <b-input v-model="remittance_USD.details" rounded maxlength="200" type="textarea"></b-input>
                  </b-field>
                </div>
              </b-tab-item>
              <b-tab-item v-if="has_cup">
                <template #header>
                  <span class="tag is-green-success mx-10 rounded-10">CUP</span>
                </template>
                <div class="text-span d-none">{{ $t('text_remittance', {percentage: fee_CUP}) }}</div>
                <div class="is-flex is-align-items-center is-justify-content-space-between">
                  <div class="text-amount">{{ $t('total_amount') }}</div>
                  <div>
                    <b-field custom-class="custom-field-label">
                      <b-input custom-class="amount-remittance" v-model="remittance_CUP.amount"
                               type="number"
                               placeholder="0"></b-input>
                    </b-field>
                  </div>
                  <div class="is-flex is-flex-direction-column">
                    <span class="text-fee-delivery" v-if="!amountPayShow_CUP">{{ $t('delivery_remittance') }}</span>
                    <span class="text-fee-delivery" v-else>{{ $t('delivery_remittance_value') }} {{
                        amountPayShow_CUP
                      }}</span>
                    <span class="text-fee-delivery" v-if="!fee_remittance_CUP">{{
                        $t('delivery_remittance_fee')
                      }}</span>
                    <span class="text-fee-delivery" v-else>{{ $t('delivery_remittance_fee_value') }} {{
                        fee_remittance_CUP
                      }}</span>
                  </div>
                </div>
                <div class="mt-20">
                  <b-field>
                    <multiselect
                        :placeholder="`${$t('card')}`"
                        v-model="remittance_CUP.card"
                        :custom-label="selectCard"
                        :searchable="true"
                        open-direction="bottom"
                        :options="cardListCUP">
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.brand }} ({{props.option.currency}})</span> <br>
                          <span class="option__small">{{ props.option.cardNumber }}</span></div>
                      </template>
                    </multiselect>
                  </b-field>
                  <b-field>
                    <b-input v-model="remittance_CUP.details" rounded maxlength="200" type="textarea"></b-input>
                  </b-field>
                </div>
              </b-tab-item>
              <b-tab-item v-if="has_eur">
                <template #header>
                  <span class="tag is-green-success mx-10 rounded-10">EUR</span>
                </template>
                <div class="text-span d-none">{{ $t('text_remittance', {percentage: fee_EUR}) }}</div>
                <div class="is-flex is-align-items-center is-justify-content-space-between">
                  <div class="text-amount">{{ $t('total_amount') }}</div>
                  <div>
                    <b-field custom-class="custom-field-label">
                      <b-input custom-class="amount-remittance" v-model="remittance_EUR.amount"
                               type="number"
                               placeholder="0"></b-input>
                    </b-field>
                  </div>
                  <div class="is-flex is-flex-direction-column">
                    <span class="text-fee-delivery" v-if="!amountPayShow_EUR">{{ $t('delivery_remittance') }}</span>
                    <span class="text-fee-delivery" v-else>{{ $t('delivery_remittance_value') }} {{
                        amountPayShow_EUR
                      }}</span>
                    <span class="text-fee-delivery" v-if="!fee_remittance_EUR">{{
                        $t('delivery_remittance_fee')
                      }}</span>
                    <span class="text-fee-delivery" v-else>{{ $t('delivery_remittance_fee_value') }} {{
                        fee_remittance_EUR
                      }}</span>
                  </div>
                </div>
                <div class="mt-20">
                  <b-field>
                    <b-input v-model="remittance_EUR.details" rounded maxlength="200" type="textarea"></b-input>
                  </b-field>
                </div>
              </b-tab-item>
              <b-tab-item v-if="has_mlc">
                <template #header>
                  <span class="tag is-green-success mx-10 rounded-10">MLC</span>
                </template>
                <div class="text-span d-none">{{ $t('text_remittance', {percentage: fee_MLC}) }}</div>
                <div class="is-flex is-align-items-center is-justify-content-space-between">
                  <div class="text-amount">{{ $t('total_amount') }}</div>
                  <div>
                    <b-field custom-class="custom-field-label">
                      <b-input custom-class="amount-remittance" v-model="remittance_MLC.amount"
                               type="number"
                               placeholder="0"></b-input>
                    </b-field>
                  </div>
                  <div class="is-flex is-flex-direction-column">
                    <span class="text-fee-delivery" v-if="!amountPayShow_MLC">{{ $t('delivery_remittance') }}</span>
                    <span class="text-fee-delivery" v-else>{{ $t('delivery_remittance_value') }} {{
                        amountPayShow_MLC
                      }}</span>
                    <span class="text-fee-delivery" v-if="!fee_remittance_MLC">{{
                        $t('delivery_remittance_fee')
                      }}</span>
                    <span class="text-fee-delivery" v-else>{{ $t('delivery_remittance_fee_value') }} {{
                        fee_remittance_MLC
                      }}</span>
                  </div>
                </div>
                <div class="mt-20">
                  <b-field>
                    <multiselect
                        :placeholder="`${$t('card')}`"
                        v-model="remittance_MLC.card"
                        :searchable="true"
                        open-direction="bottom"
                        :custom-label="selectCard"
                        :options="cardListMLC">
                      <template slot="option" slot-scope="props">
                        <div class="option__desc">
                          <span class="option__title">{{ props.option.brand }} ({{props.option.currency}})</span> <br>
                          <span class="option__small">{{ props.option.cardNumber }}</span></div>
                      </template>
                    </multiselect>
                  </b-field>
                  <b-field>
                    <b-input v-model="remittance_MLC.details" rounded maxlength="200" type="textarea"></b-input>
                  </b-field>
                </div>
              </b-tab-item>
            </b-tabs>

          </div>
          <div class="buttons mt-40 is-flex is-align-items-center is-justify-content-flex-end">
            <button @click="save" class="button save is-primary">{{ $t('save') }}</button>
          </div>
          <b-modal
              v-model="isModalIssuerActive"
              has-modal-card
              trap-focus
              :destroy-on-hide="false"
              aria-role="dialog"
              aria-label="Example Modal"
              aria-modal>
            <template #default="props">
              <modal-new-issuer @email="newIssuer($event)" @refresh="refreshIssuer"
                                @close="props.close"></modal-new-issuer>
            </template>
          </b-modal>
          <b-modal
              v-model="isModalRecipientActive"
              has-modal-card
              trap-focus
              :destroy-on-hide="false"
              aria-role="dialog"
              aria-label="Example Modal"
              aria-modal>
            <template #default="props">
              <modal-new-recipient :selected="issuer" @receiver="newRecipient($event)" @refresh="refreshDealer"
                                   @close="props.close"></modal-new-recipient>
            </template>
          </b-modal>
        </section>

      </div>
    </section>
  </div>
</template>

<script>

import ModalNewIssuer from "@/components/modals/modalNewIssuer";
import ModalNewRecipient from "@/components/modals/modalNewRecipient";
import Multiselect from "vue-multiselect";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {USER_TYPE} from "@/enum/userType";
import {deleteStorage, getStorage, notification, saveStorage, sendToast} from "@/utils";
import {CURRENCY} from "@/enum/currency";
import {COLOR_TOAST} from "@/enum/colorToast";
import {AGENT_TYPE} from "@/enum/agentType";
import {TYPE_ERROR} from "@/enum/typeError";

export default {
  name: "modalNewRemittance",
  components: {ModalNewIssuer, ModalNewRecipient, Multiselect},
  props: ['edit'],
  data() {
    return {
      has_usd: false,
      has_cup: false,
      has_eur: false,
      has_mlc: false,
      isModalIssuerActive: false,
      isModalRecipientActive: false,
      addressee: null,
      issuer: null,
      currency: null,
      nameRecipient: null,
      paymentGroupsValueUSD: null,
      paymentGroupsValueCUP: null,
      paymentGroupsValueEUR: null,
      paymentGroupsValueMLC: null,
      addresseeList: [],
      issuerList: [],
      agencyList: [],
      agentList: [],
      cardListMLC: [],
      cardListCUP: [],
      card:null,
      received: null,
      fee_USD: null,
      fee_CUP: null,
      fee_EUR: null,
      fee_MLC: null,
      fee_remittance_USD: null,
      fee_remittance_CUP: null,
      fee_remittance_EUR: null,
      fee_remittance_MLC: null,
      amountPayShow_USD: null,
      amountPayShow_MLC: null,
      amountPayShow_CUP: null,
      amountPayShow_EUR: null,
      paymentsGroupsList: [],
      agency: null,
      agent: null,
      userTypes: USER_TYPE,
      remittancesPost: [],
      remittance_USD: {
        address: null,
        senderId: null,
        details: null,
        agencyId: null,
        agentId: null,
        amount: null,
        recipentId: null,
        currency: CURRENCY.USD,
        paymentsGroup: null,
        amountPay: null
      },
      remittance_EUR: {
        address: null,
        senderId: null,
        details: null,
        agencyId: null,
        agentId: null,
        amount: null,
        recipentId: null,
        currency: CURRENCY.USD,
        paymentsGroup: null,
        amountPay: null
      },
      remittance_CUP: {
        address: null,
        senderId: null,
        details: null,
        agencyId: null,
        agentId: null,
        amount: null,
        recipentId: null,
        currency: CURRENCY.CUP,
        paymentsGroup: null,
        amountPay: null,
        card:null
      },
      remittance_MLC: {
        address: null,
        senderId: null,
        details: null,
        agencyId: null,
        agentId: null,
        amount: null,
        recipentId: null,
        currency: CURRENCY.MLC,
        paymentsGroup: null,
        amountPay: null,
        card: null,
      },

    }
  },
  computed: {
    ...mapGetters({
      currencyList: 'GET_CURRENCIES',
      user: 'GET_USER',
      paymentGroupUSD: 'GET_PAYMENTS_GROUPS_REMITTANCES_USD',
      paymentGroupCUP: 'GET_PAYMENTS_GROUPS_REMITTANCES_CUP',
      paymentGroupEUR: 'GET_PAYMENTS_GROUPS_REMITTANCES_EUR',
      paymentGroupMLC: 'GET_PAYMENTS_GROUPS_REMITTANCES_MLC'

    })
  },
  methods: {
    ...mapActions({
      fetchReceived: 'FETCH_RECIPIENT',
      fetchReceivedBySender: 'FETCH_RECIPIENT_BY_ISSUER',
      fetchAgency: 'FETCH_AGENCY',
      fetchAgent: 'FETCH_AGENT',
      fetchFees: 'FETCH_FEES_REMITTANCE',
      fetchAgentByAgency: 'FETCH_AGENTS_BY_AGENCY',
      fetchSender: 'FETCH_ISSUER',
      fetchSenderByAgency: 'FETCH_ISSUER_BY_AGENCY',
      fetchPaymentsGroups: 'FETCH_PAYMENTS_GROUPS',
      saveInvoice: 'SAVE_INVOICE'
    }),
    ...mapMutations({
      setPaymentGroupUSD: 'SET_PAYMENTS_GROUPS_REMITTANCES_USD',
      setPaymentGroupCUP: 'SET_PAYMENTS_GROUPS_REMITTANCES_CUP',
      setPaymentGroupEUR: 'SET_PAYMENTS_GROUPS_REMITTANCES_EUR',
      setPaymentGroupMLC: 'SET_PAYMENTS_GROUPS_REMITTANCES_MLC'
    }),
    newRecipient(event) {
      this.refreshDealer()
          .then(value => {
            this.addressee = value.find(f => f.id === event)
          })
    },
    newIssuer(event) {
      this.refreshIssuer()
          .then(value => {
            this.issuer = value.find(f => f.email === event)
          })

    },
    selectNameIssuer({name, phoneNumber}) {
      return `(${phoneNumber})--${name}`
    },
    selectName({name}) {
      return name
    },
    selectCard({cardNumber}) {
      return cardNumber
    },
    currencyFromTo({name, currencyFrom, currencyTo, rate}) {
      return `${name} - ${currencyFrom} — ${currencyTo} - (${rate}%)`
    },

    save() {
      if (this.user.userType !== this.userTypes.MANAGER) {
        if (this.paymentGroupsValueUSD) {
          this.remittance_USD.paymentsGroup = this.paymentGroupsValueUSD.id
        }
        if (this.paymentGroupsValueCUP) {
          this.remittance_CUP.paymentsGroup = this.paymentGroupsValueCUP.id
        }
        if (this.paymentGroupsValueEUR) {
          this.remittance_EUR.paymentsGroup = this.paymentGroupsValueEUR.id
        }
        if (this.paymentGroupsValueMLC) {
          this.remittance_MLC.paymentsGroup = this.paymentGroupsValueMLC.id
        }
      }
      if (this.remittance_USD.amount) {
        this.remittance_USD.amount = Number(this.remittance_USD.amount)
        if (!this.remittance_USD.senderId) {
          notification('Debe seleccionar un emisor', TYPE_ERROR.WARNING)
          return false
        }
        if (!this.remittance_USD.recipentId) {
          notification('Debe seleccionar un destinatario', TYPE_ERROR.WARNING)
          return false
        }
        this.remittancesPost.push(this.remittance_USD)
      }
      if (this.remittance_EUR.amount) {
        this.remittance_EUR.amount = Number(this.remittance_EUR.amount)
        if (!this.remittance_EUR.senderId) {
          notification('Debe seleccionar un emisor', TYPE_ERROR.WARNING)
          return false
        }
        if (!this.remittance_EUR.recipentId) {
          notification('Debe seleccionar un destinatario', TYPE_ERROR.WARNING)
          return false
        }
        this.remittancesPost.push(this.remittance_EUR)
      }
      if (this.remittance_CUP.amount) {
        this.remittance_CUP.card = this.remittance_CUP.card.cardNumber
        this.remittance_CUP.amount = Number(this.remittance_CUP.amount)
        if (!this.remittance_CUP.senderId) {
          notification('Debe seleccionar un emisor', TYPE_ERROR.WARNING)
          return false
        }
        if (!this.remittance_CUP.recipentId) {
          notification('Debe seleccionar un destinatario', TYPE_ERROR.WARNING)
          return false
        }
        this.remittancesPost.push(this.remittance_CUP)
      }
      if (this.remittance_MLC.amount) {
        this.remittance_MLC.card =this.remittance_MLC.card.cardNumber
        this.remittance_MLC.amount = Number(this.remittance_MLC.amount)
        if (!this.remittance_MLC.senderId) {
          notification('Debe seleccionar un emisor', TYPE_ERROR.WARNING)
          return false
        }
        if (!this.remittance_MLC.recipentId) {
          notification('Debe seleccionar un destinatario', TYPE_ERROR.WARNING)
          return false
        }
        this.remittancesPost.push(this.remittance_MLC)
      }
      if (this.remittancesPost.length) {
        this.saveInvoice(this.remittancesPost)
            .then(() => {
              this.closed()
            }).catch(() => {
          this.remittancesPost = []
        })
            .finally(() => {
              this.remittancesPost = []
            })
      } else {
        sendToast('Invalid Data', COLOR_TOAST.WARNING)
      }


    },
    closed() {
      this.$emit('close')
      this.$emit('reload')
      this.deleteStorePG()
      window.location.reload()
    },
    refreshIssuer() {
      return new Promise(resolve => {
        if (this.agency) {
          this.fetchSenderByAgency(this.agency.id)
              .then(value => {
                this.issuerList = value.data
                resolve(this.issuerList)
              })
        } else {
          this.getIssuers()
              .then(value => {
                this.issuerList = value.data
                resolve(this.issuerList)
              })
        }
      })


    },
    refreshDealer() {
      if (this.issuer) {
        return new Promise(resolve => {
          this.fetchReceivedBySender(this.issuer.id)
              .then(value => {
                this.addresseeList = value.data
                resolve(this.addresseeList)
              })
        })

      }

    },
    getPaymentGroup(currency) {
      let pg;
      if (currency === CURRENCY.USD && this.paymentGroupsValueUSD !== null) {
        pg = this.paymentGroupsValueUSD
      } else if (currency === CURRENCY.CUP && this.paymentGroupsValueCUP !== null) {
        pg = this.paymentGroupsValueCUP
      } else if (currency === CURRENCY.EUR && this.paymentGroupsValueEUR !== null) {
        pg = this.paymentGroupsValueEUR
      } else if (currency === CURRENCY.MLC && this.paymentGroupsValueMLC !== null) {
        pg = this.paymentGroupsValueMLC
      } else {
        switch (currency) {
          case CURRENCY.USD:
            pg = getStorage('paymentGroupsUSD')
            break;
          case CURRENCY.CUP:
            pg = getStorage('paymentGroupsCUP')
            break;
          case CURRENCY.EUR:
            pg = getStorage('paymentGroupsEUR')
            break;
          case CURRENCY.MLC:
            pg = getStorage('paymentGroupsMLC')
            break;
        }
      }
      return pg
    },
    calculateRemittanceUSD() {
      let pg = this.getPaymentGroup(CURRENCY.USD)
      if (pg && pg.isActive) {
        this.remittance_USD.paymentsGroup = pg.id
        let query = {
          amount: this.remittance_USD.amount,
          paymentGroup: pg.id
        }
        this.fetchFees(query)
            .then(response => {
              this.remittance_USD.amountPay = response.usdAmountPay
              this.fee_remittance_USD = response.usdFee.toFixed(2)
              this.amountPayShow_USD = this.remittance_USD.amountPay.toFixed(2)
            }).catch(() => {
          this.remittance_USD.amountPay = null
          this.fee_remittance_USD = null
          this.amountPayShow_USD = null
        })
      } else {
        if (pg === undefined) {
          this.remittance_USD.amountPay = this.remittance_USD.amount
          this.fee_remittance_USD = '0'
          this.amountPayShow_USD = this.remittance_USD.amount
        } else {
          notification('Grupo de pago inactivo', TYPE_ERROR.WARNING)
        }
      }
    },
    calculateRemittanceMLC() {
      let pg = this.getPaymentGroup(CURRENCY.MLC)
      if (pg && pg.isActive) {
        this.remittance_MLC.paymentsGroup = pg.id
        let query = {
          amount: this.remittance_MLC.amount,
          paymentGroup: pg.id
        }
        this.fetchFees(query)
            .then(response => {
              this.remittance_MLC.amountPay = response.usdAmountPay
              this.fee_remittance_MLC = response.usdFee.toFixed(2)
              this.amountPayShow_MLC = this.remittance_MLC.amountPay.toFixed(2)
            }).catch(() => {
          this.remittance_MLC.amountPay = null
          this.fee_remittance_MLC = null
          this.amountPayShow_MLC = null
        })
      } else {
        if (pg === undefined) {
          this.remittance_MLC.amountPay = this.remittance_MLC.amount
          this.fee_remittance_MLC = '0'
          this.amountPayShow_MLC = this.remittance_MLC.amount
        } else {
          notification('Grupo de pago inactivo', TYPE_ERROR.WARNING)
        }
      }
    },
    calculateRemittanceEUR() {
      let pg = this.getPaymentGroup(CURRENCY.EUR)
      if (pg && pg.isActive) {
        this.remittance_EUR.paymentsGroup = pg.id
        let query = {
          amount: this.remittance_EUR.amount,
          paymentGroup: pg.id
        }
        this.fetchFees(query)
            .then(response => {
              this.remittance_EUR.amountPay = response.usdAmountPay
              this.fee_remittance_EUR = response.usdFee.toFixed(2)
              this.amountPayShow_EUR = this.remittance_EUR.amountPay.toFixed(2)
            }).catch(() => {
          this.remittance_EUR.amountPay = null
          this.fee_remittance_EUR = null
          this.amountPayShow_EUR = null
        })
      } else {
        if (pg === undefined) {
          this.remittance_EUR.amountPay = this.remittance_EUR.amount
          this.fee_remittance_EUR = '0'
          this.remittance_EUR = this.remittance_EUR.amount
        } else {
          notification('Grupo de pago inactivo', TYPE_ERROR.WARNING)
        }
      }

    },
    calculateRemittanceCUP() {
      let pg = this.getPaymentGroup(CURRENCY.CUP)
      if (pg && pg.isActive) {
        this.remittance_CUP.paymentsGroup = pg.id
        let query = {
          amount: this.remittance_CUP.amount,
          paymentGroup: pg.id
        }
        this.fetchFees(query)
            .then(response => {
              this.remittance_CUP.amountPay = response.usdAmountPay
              this.fee_remittance_CUP = response.usdFee.toFixed(2)
              this.amountPayShow_CUP = this.remittance_CUP.amountPay.toFixed(2)
            }).catch(() => {
          this.remittance_CUP.amountPay = null
          this.fee_remittance_CUP = null
          this.amountPayShow_CUP = null
        })
      } else {
        if (pg === undefined) {
          this.remittance_CUP.amountPay = this.remittance_CUP.amount
          this.fee_remittance_CUP = '0'
          this.amountPayShow_CUP = this.remittance_CUP.amount
        } else {
          notification('Grupo de pago inactivo', TYPE_ERROR.WARNING)
        }
      }

    },
    getIssuers(current = 1, record = 0) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: null,
      };
      return new Promise(resolve => {
        this.fetchSender(data)
            .then(value => {
              resolve(value)
            })
      })
    },
    getAgents(current = 1, record = 0, id = null) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: id,
      };
      return new Promise(resolve => {
        this.fetchAgent(data)
            .then(value => {
              resolve(value)
            })
      })
    },
    getPaymentsGroup(current = 1, record = 0, type = 0) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: null,
        type: type
      };
      return new Promise(resolve => {
        this.fetchPaymentsGroups(data).then(value => resolve(value))
      })

    },
    getAgencies(current = 1, record = 0, id = null) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: id,
      };
      return new Promise(resolve => {
        this.fetchAgency(data)
            .then(value => {
              resolve(value)
            })
      })

    },
    deleteStorePG() {
      deleteStorage('paymentGroupsUSD')
      deleteStorage('paymentGroupsCUP')
      deleteStorage('paymentGroupsEUR')
    },
    setPG(value) {
      if (value.paymentGroups.find(p => p.currencyTo === CURRENCY.USD)) {
        this.setPaymentGroupUSD(value.paymentGroups.find(p => p.currencyTo === CURRENCY.USD))
        this.has_usd = true
      }
      if (value.paymentGroups.find(p => p.currencyTo === CURRENCY.CUP)) {
        this.setPaymentGroupCUP(value.paymentGroups.find(p => p.currencyTo === CURRENCY.CUP))
        this.has_cup = true
      }
      if (value.paymentGroups.find(p => p.currencyTo === CURRENCY.EUR)) {
        this.setPaymentGroupEUR(value.paymentGroups.find(p => p.currencyTo === CURRENCY.EUR))
        this.has_eur = true
      }
      if (value.paymentGroups.find(p => p.currencyTo === CURRENCY.MLC)) {
        this.setPaymentGroupMLC(value.paymentGroups.find(p => p.currencyTo === CURRENCY.MLC))
        this.has_mlc = true
      }
    }

  },
  watch: {
    issuer: function () {
      this.remittance_USD.senderId = this.issuer.id
      this.remittance_CUP.senderId = this.issuer.id
      this.remittance_EUR.senderId = this.issuer.id
      this.remittance_MLC.senderId = this.issuer.id
      this.fetchReceivedBySender(this.issuer.id)
          .then(value => {
            this.addresseeList = value.data
          })
    },
    agency: function () {
      if (!this.agency) {
        this.deleteStorePG()
      }
      this.remittance_USD.agencyId = this.agency.id
      this.remittance_CUP.agencyId = this.agency.id
      this.remittance_EUR.agencyId = this.agency.id
      this.remittance_MLC.agencyId = this.agency.id
      this.issuer = null
      this.agent = null
      this.addressee = null
      this.setPG(this.agency)
      saveStorage('agency_issuer', this.agency)
      this.fetchAgentByAgency(this.agency.id)
          .then(value => {
            this.agentList = value.data.filter(f => f.agentType === AGENT_TYPE.EXTERNAL)
          })
      this.fetchSenderByAgency(this.agency.id)
          .then(value => {
            this.issuerList = value.data
          })
    },
    agent: function () {
      if (!this.agent) {
        this.deleteStorePG()
      }
      this.remittance_USD.agentId = this.agent.id
      this.remittance_CUP.agentId = this.agent.id
      this.remittance_EUR.agentId = this.agent.id
      this.remittance_MLC.agentId = this.agent.id
      if (this.agent.agentType === AGENT_TYPE.EXTERNAL) {
        this.setPG(this.agent)
      } else {
        this.getAgencies(1, 1, this.agent.agencyId)
            .then(value => {
              this.setPG(value)
            })
      }
    },
    addressee: function () {
      this.remittance_USD.recipentId = this.addressee.id
      this.remittance_CUP.recipentId = this.addressee.id
      this.remittance_EUR.recipentId = this.addressee.id
      this.remittance_MLC.recipentId = this.addressee.id
      this.cardListMLC = this.addressee.cardNumbers ? this.addressee.cardNumbers.filter(f=>f.currency === CURRENCY.MLC) : []
      this.cardListCUP =this.addressee.cardNumbers ? this.addressee.cardNumbers.filter(f=>f.currency === CURRENCY.CUP) :[]
      this.has_cup = !!this.cardListCUP.length
      this.has_mlc = !!this.cardListMLC.length
    },
    paymentGroupsValueUSD: function () {
      this.setPaymentGroupUSD(this.paymentGroupsValueUSD)
      this.remittance_USD.paymentsGroup = this.paymentGroupsValueUSD.id
      this.calculateRemittanceUSD()
    },
    paymentGroupsValueEUR: function () {
      this.setPaymentGroupEUR(this.paymentGroupsValueEUR)
      this.remittance_EUR.paymentsGroup = this.paymentGroupsValueEUR.id
      this.calculateRemittanceEUR()
    },
    paymentGroupsValueCUP: function () {
      this.setPaymentGroupCUP(this.paymentGroupsValueCUP)
      this.remittance_CUP.paymentsGroup = this.paymentGroupsValueCUP.id
      this.calculateRemittanceCUP()
    },
    paymentGroupsValueMLC: function () {
      this.setPaymentGroupMLC(this.paymentGroupsValueMLC)
      this.remittance_MLC.paymentsGroup = this.paymentGroupsValueMLC.id
      this.calculateRemittanceMLC()
    },
    'remittance_USD.amount': function () {
      this.calculateRemittanceUSD()
    },
    'remittance_CUP.amount': function () {
      this.calculateRemittanceCUP()
    },
    'remittance_EUR.amount': function () {
      this.calculateRemittanceEUR()
    },
    'remittance_MLC.amount': function () {
      this.calculateRemittanceMLC()
    },
  },
  created() {
    this.getIssuers()
        .then(value => {
          this.issuerList = value.data
        })
    switch (this.user.userType) {
      case USER_TYPE.MANAGER:
        this.getPaymentsGroup()
            .then(value => {
              this.paymentsGroupsList = value
              this.has_cup = this.paymentsGroupsList.filter(p => p.currencyTo === CURRENCY.CUP).length > 0
              this.has_usd = this.paymentsGroupsList.filter(p => p.currencyTo === CURRENCY.USD).length > 0
              this.has_eur = this.paymentsGroupsList.filter(p => p.currencyTo === CURRENCY.EUR).length > 0
              this.has_mlc = this.paymentsGroupsList.filter(p => p.currencyTo === CURRENCY.MLC).length > 0
            })
        this.getAgents()
            .then(value => {
              this.agentList = value.data.filter(f => f.agentType === AGENT_TYPE.EXTERNAL)
            })
        this.getAgencies()
            .then(value => {
              this.agencyList = value.data
            })
        break;
      case USER_TYPE.AGENCY:
        this.getAgents()
            .then(value => {
              this.agentList = value.data.filter(f => f.agentType === AGENT_TYPE.EXTERNAL)
            })
        this.getAgencies(1, 1, this.user.id)
            .then(value => {
              this.setPG(value)
            })
        break;
      case USER_TYPE.AGENT:
        this.getAgents(1, 1, this.user.id)
            .then(value => {
              if (value.agentType === AGENT_TYPE.EXTERNAL) {
                this.setPG(value)
              } else {
                this.getAgencies(1, 1, value.agencyId)
                    .then(value => {
                      this.setPG(value)
                    })
              }

            })
    }
  }
}
</script>

<style lang="scss">
.button {
  &.new {
    width: 48px;
    height: 48px;
    border-radius: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 180%;
    letter-spacing: 0.5px;
    color: #FFFFFF;

    &:hover {
      color: #0B2B46;
    }

    &:active {
      color: #0B2B46;
    }

    .mdi-plus {
      font-size: 38px;
    }
  }

  &.new-coin {
    width: 274px;
    height: 48px;
    font-weight: bold;
    font-size: 15px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: #666BAA;
    border: 1px solid #666BAA;;

    .mdi-plus {
      font-size: 38px;
    }
  }

  &.save {
    width: 274px;
    height: 48px;
    border-radius: 10px;
  }
}

.custom-field-label {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 32px !important;
  letter-spacing: 0.5px !important;
  color: #AEAEAE !important;
}

.currency {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #666BAA;
}

.text-span {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 32px;
  letter-spacing: 0.5px;
  margin-top: 20px;
  color: #AEAEAE;
}

.text-amount {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #AEAEAE;
}

.text-fee-delivery {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #0B2B46;
}
</style>
