<template>
  <div class="modal-card" style="width: auto">
    <section class="modal-card-body">
      <div class="cancel-btn my-10  cursor-pointer">
        <span class="title">{{ $t('cancel_agency') }}</span>
      </div>
      <div class="cancel-btn my-10 cursor-pointer">
        <span class="title">{{ $t('cancel') }}</span>
      </div>
    </section>

  </div>
</template>

<script>

export default {
  name: "modalCancelRemittance",
  data() {
    return {
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">
.modal-card {
  border-radius: 10px;
}

.btn-continue {
  width: 150px !important;
  height: 40px !important;
  font-size: 20px;
}

.cancel-btn {
  background: #FCFCFC;
  border: 1px solid #FDC4D1;
  box-sizing: border-box;
  border-radius: 10px;
  width: 314px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.5px;
    font-style: normal;
  }

}
</style>