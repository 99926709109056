<template>
  <div class="modal-card" style="width: auto">
    <section class="modal-card-body is-flex is-align-items-center is-flex-direction-column">
      <div v-if="false" class="cancel-btn my-10  cursor-pointer">
        <span class="title">{{ $t('cancel_agency') }}</span>
      </div>
      <div
          v-if="(remittance.transactionStatus === TRANSACTION_STATUS.PENDING || remittance.transactionStatus === TRANSACTION_STATUS.SHIPPING) && user.userType !== userTypes.DEALER "
          @click="changeToCancel" class="cancel-btn my-10 cursor-pointer">
        <span class="title">{{ $t('cancel') }}</span>
      </div>
      <div
          v-if="(remittance.transactionStatus === TRANSACTION_STATUS.PENDING
      || remittance.transactionStatus === TRANSACTION_STATUS.SHIPPING) &&
           ((user.userType === userTypes.DEALER && this.remittance.currency !== CURRENCY.MLC)
            || user.userType === userTypes.MANAGER)"
          @click="changeToShippingManager"
          class="cancel-btn my-10 cursor-pointer">
        <span class="title">{{ $t('shipping_status') }}</span>
      </div>
      <template v-if="shipping && (remittance.transactionStatus === TRANSACTION_STATUS.PENDING
      || remittance.transactionStatus === TRANSACTION_STATUS.SHIPPING)">
        <b-field>
          <multiselect
              :placeholder="$t('dealer')"
              v-model="dealer"
              :custom-label="selectName"
              open-direction="bottom"
              :show-no-results="false"
              :options="dealerList">
          </multiselect>
        </b-field>
        <div class="is-flex is-justify-content-center">
          <b-button @click="changeToShipping(true)" class="btn btn-continue mx-10" type="is-primary">
            {{ $t('save') }}
          </b-button>
        </div>
      </template>
      <div
          v-if="remittance.transactionStatus === TRANSACTION_STATUS.SHIPPING && (user.userType === userTypes.DEALER || user.userType === userTypes.DELIVERYMAN || user.userType === userTypes.MANAGER)"
          @click="completed=!completed"
          class="cancel-btn my-10 cursor-pointer">
        <span class="title">{{ $t('completed_status') }}</span>
      </div>
      <template v-if="completed">
        <div>
          <div class="is-flex is-justify-content-space-around">
            <div class="is-flex-direction-column is-flex">
              <b-field>
                <b-upload drag-drop native v-model="documents.front">
            <span class="file-cta">
                <b-icon class="file-icon" icon="upload"></b-icon>
                <span class="file-label">{{ $t('evidences') }}</span>
            </span>
                </b-upload>
              </b-field>
              <div>
                <img class="width-150" :src="previewDni" alt="">
              </div>
            </div>
          </div>
          <div class="is-flex is-justify-content-center">
            <b-button @click="changeToCompleted" class="btn btn-continue mx-10" type="is-primary">
              {{ $t('save') }}
            </b-button>
          </div>
        </div>
      </template>
      <div v-if="false" @click="changeToError" class="cancel-btn my-10 cursor-pointer">
        <span class="title">{{ $t('error_status') }}</span>
      </div>
    </section>

  </div>
</template>

<script>
import cover from '@/assets/img/upload.png'
import {mapActions, mapGetters} from "vuex";
import {TRANSACTION_STATUS} from "@/enum/transactionStatus";
import {USER_TYPE} from "@/enum/userType";
import Multiselect from "vue-multiselect";
import {DEALER_TYPE} from "@/enum/dealerType";
import {CURRENCY} from '@/enum/currency';
export default {
  name: "modalChangeStatusRemittance",
  components: {Multiselect},
  props: ['remittance'],
  data() {
    return {
      cover,
      CURRENCY,
      TRANSACTION_STATUS,
      userTypes: USER_TYPE,
      completed: false,
      shipping: false,
      previewDni: null,
      previewFirm: null,
      documents: {
        front: null,
        firm: null
      },
      dealer: null,
      dealerId: null,
      dealerList: []
    }
  },
  computed: {
    ...mapGetters({
      user: 'GET_USER'
    })
  },
  methods: {
    ...mapActions({
      acceptRemittance: 'ACCEPT_REMITTANCE',
      errorRemittance: 'ERROR_REMITTANCE',
      cancelRemittance: 'CANCEL_REMITTANCE',
      completeRemittance: 'COMPLETE_REMITTANCE',
      fetchDealer: 'FETCH_DEALER'
    }),

    save() {

    },
    selectName({name}) {
      return name
    },
    getDealers(current = 1, record = 0, id = null) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: id,
        type: this.user.userType === USER_TYPE.DEALER ? DEALER_TYPE.DEALER : null
      };
      this.fetchDealer(data)
          .then(value => {
            this.dealerList = value.data
            this.shipping = !this.shipping
          })
    },
    changeToError() {
      let data = {
        id: this.remittance.id,
        message: 'Datos Incorrectos'
      }
      this.errorRemittance(data)
          .then(() => {
            this.reload()
          })
    },
    changeToCompleted() {
      let data = {
        front: this.documents.front,
        firm: this.documents.firm,
        id: this.remittance.id
      }
      this.completeRemittance(data)
          .then(() => {
            this.reload()
          })
    },
    changeToShippingManager() {
      if (this.user.userType !== USER_TYPE.DEALER && this.remittance.currency !== CURRENCY.MLC) {
        let data = {
          remittanceId: this.remittance.id,
        }
        this.acceptRemittance(data)
            .then(() => {
              this.reload()
            })
      } else {
        this.getDealers()
      }

    },
    changeToShipping(isDealer = false) {
      let data = {
        remittanceId: this.remittance.id,
        dealerId: this.dealerId,
        query: isDealer && this.dealerId ? `?deliveryManId=${this.dealerId}` : null
      }
      this.acceptRemittance(data)
          .then(() => {
            this.reload()
          })
    },
    changeToCancel() {
      this.cancelRemittance(this.remittance.id)
          .then(() => {
            this.reload()
          })
    },
    reload(){
      this.$emit('close')
      this.$emit('reload')
      window.location.reload()
    },
    getImgUrl(file) {
      return new Promise(resolve => {
        if (file !== undefined) {
          const fr = new FileReader();
          fr.readAsDataURL(file);
          fr.addEventListener('load', () => {
            resolve(fr.result)
          })
        }
      })

    }
  },
  watch: {
    dealer: function () {
      this.dealerId = this.dealer.id
    },
    'documents.front': async function () {
      this.previewDni = await this.getImgUrl(this.documents.front)
    },
    'documents.firm': async function () {
      this.previewFirm = await this.getImgUrl(this.documents.firm)
    }
  }
}
</script>

<style scoped lang="scss">
.modal-card {
  border-radius: 10px;
}

.btn-continue {
  width: 150px !important;
  height: 40px !important;
  font-size: 20px;
}

.cancel-btn {
  background: #FCFCFC;
  border: 1px solid #FDC4D1;
  box-sizing: border-box;
  border-radius: 10px;
  width: 314px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.5px;
    font-style: normal;
  }

}
</style>